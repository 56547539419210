import React, { useState } from "react"
import { toast } from "react-toastify"
import { EmailShareButton, FacebookShareButton, TwitterShareButton, } from "react-share"
import { Button } from "../../"
import ModalDonation from "../../Common/ModalDonation"
import UserRedemptionPageHeader from "../UserRedemptionPageHeader"
import styles from "./styles.module.scss"


function SuccessUserRedemption({ successData }) {
  const [method, setMethod] = useState()
  const [isTooltipVisible, setTooltipVisible] = useState(false)
  const [isInfoVisible, setInfoVisible] = useState(false)
  const { charities, displayData } = successData
  const { Caption, HeaderImageURL, WelcomeMessage, MainColor, AccentColor } = displayData;
  const strCharities = charities
    ?.map(({ CharityName }) => CharityName)
    .join(", ")

  const onModalClose = message => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    })
    setMethod()
  }
  return (
    <>
      <UserRedemptionPageHeader headerImageURL={HeaderImageURL} welcomeMessage={WelcomeMessage} caption={Caption} mainColor={MainColor} />
      <div
        className={styles.bar}
        style={{ backgroundColor: MainColor }}></div>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.title} style={{ color: MainColor }}>
            Success!
          </div>
          <div className={styles.row}>
            <div className={styles.info}>
              {isTooltipVisible && (
                <div
                  className={styles.infoTooltip}
                  style={{ color: MainColor }}>
                  How is my donation sent to the charities I choose?
                </div>
              )}
              <div
                className={styles.infoIcon}
                onMouseEnter={() => setTooltipVisible(true)}
                onMouseLeave={() => setTooltipVisible(false)}>
                <i
                  className="fa-solid fa-circle-info"
                  onClick={() => {
                    setInfoVisible(!isInfoVisible)
                    setTooltipVisible(false)
                  }}
                  onBlur={() => setInfoVisible(false)}></i>
              </div>

              {isInfoVisible && (
                <div
                  className={styles.infoContent}
                  onBlur={() => setInfoVisible(false)}>
                  Funds are allocated in a bulk check to the selected charities
                  quarterly.
                  <br />
                  <br />
                  Charities receive donations from us anonymously, in order to
                  protect the privacy of both the donor and recipient. (unless you
                  have opted in to share info) If your designation was to several
                  charities, then the funds are divided equally.
                  <br />
                  <br />
                  If you wish to apply the donation to a charity's particular
                  event or program; we suggest that you print a confirmation to
                  verify your designation. It is up to each charity, as to their
                  policy for 'credits' towards their programs.
                </div>
              )}
            </div>
            <div className={styles.description}>
              Your gift amount will be distributed evenly between
              <br />
              <span className={styles.selCharities} style={{ color: AccentColor }}>{strCharities}</span>
            </div>
          </div>

          <div className={styles.lblMethod} style={{ color: AccentColor }}>
            Would you like a receipt?
          </div>
          <div className={styles.btnWrapper}>
            <Button
              className={styles.btn}
              outline
              style={{
                color: method === "donate" ? AccentColor : MainColor,
                borderColor: method === "donate" ? AccentColor : MainColor,
              }}
              color={method === "donate" ? "yellow" : "red"}
              onClick={() => setMethod("donate")}>
              Donation Receipt
            </Button>
            <Button
              className={styles.btn}
              outline
              style={{
                color: method === "tax" ? AccentColor : MainColor,
                borderColor: method === "tax" ? AccentColor : MainColor,
              }}
              color={method === "tax" ? "yellow" : "red"}
              onClick={() => setMethod("tax")}>
              Tax Receipt
            </Button>
          </div>
          <a className={styles.purchaseLink} style={{ backgroundColor: AccentColor, color: MainColor }}
            href="/#GiveGiftCards">Purchase your own Charity Gift</a>
          <div
            className={styles.socialLinksContainer}
            style={{ color: MainColor }}>
            Spread the joy, and share your donation!
            <div className={styles.socialLinks}>
              <EmailShareButton
                url="https://www.charitygiftcertificates.org"
                subject="I found this way of giving the most meaningful gift ever! Check it out."
                body="Thank you for benefiting the charity of your choice through our service for charitable giving.
            Funds are forwarded quarterly to the selected charities anonymously, to protect the privacy of users.
            Allocations are sent to charities quarterly."
                className={styles.btnShare}>
                <i className="fa-solid fa-envelope"></i>
              </EmailShareButton>
              <FacebookShareButton
                url="https://www.charitygiftcertificates.org"
                quote="I found this way of giving the most meaningful gift ever! Check it out."
                className={styles.btnShare}>
                <i className="fa-brands fa-facebook"></i>
              </FacebookShareButton>
              <TwitterShareButton
                url="https://www.charitygiftcertificates.org"
                title="I found this way of giving the most meaningful gift ever! Check it out."
                className={styles.btnShare}>
                <i className="fa-brands fa-twitter"></i>
              </TwitterShareButton>
            </div>
          </div>
        </div>
      </div>
      <ModalDonation type={method} onClose={onModalClose} />
    </>
  )
}

export default SuccessUserRedemption
