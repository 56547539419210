import { get, post } from "./IApi"

import small_slider1 from "../images/slider-small-1.jpg"
import small_slider2 from "../images/slider-small-2.jpg"
import small_slider3 from "../images/slider-small-3.jpg"
import small_slider4 from "../images/slider-small-4.jpg"
import small_slider5 from "../images/slider-small-5.jpg"
import small_slider6 from "../images/slider-small-6.jpg"

import big_slider1 from "../images/slider1.jpg"
import big_slider2 from "../images/slider2.jpg"
import big_slider3 from "../images/slider3.jpg"

import custom_ribbon from "../images/Ribbon.svg"
import custom_holiday from "../images/Holiday.svg"
import custom_full from "../images/Fully Custom.svg"

import { filterCharities } from "./Utilities"

//keep track of which parts of the charity list are currently being loaded
window.loadingCharityList ??= new Set([]);
const loadingCharityList = window.loadingCharityList;

// Digital cards
export const GetCards = async occasionId => {
  return await get(`digitalCards/cards/${occasionId}`)
}

export const GetCardAndOccasion = async cardId => {
  const response = await get(`digitalCards/card/${cardId}`)
  return response.Succeeded
    ? { Occasion: response.OccasionInfo, Card: response.CardInfo }
    : {}
}
export const GetCardPreview = async payload => {
  return await post(payload, "digitalCards/preview/v2")
}

export const AddDigitalCards = async (payload, itemIndex) => {
  if (Number.isInteger(parseInt(itemIndex))) {
    return await post(payload, `shoppingcart/digitalCards/${itemIndex}`)
  }
  return await post(payload, `shoppingcart/digitalCards/`)
}

// Physical cards
export async function GetPhysicalCardTypes(occasionId, hiddenOccasionIds) {
  const response = await get(
    `purchaseGeneral/physicalCardTypes/${occasionId || 0}${hiddenOccasionIds ? "/" + hiddenOccasionIds : ""
    }`,
  )

  return response.Succeeded ? response.List : response
}

export const GetPhysicalShippingTypes = async () => {
  return await get("purchaseGeneral/shippingTypes")
  // return response.Succeeded ? response.List : response;
}
export const GetCustomShippingTypes = async () => {
  return await get("purchaseGeneral/shippingTypes/true")
  // return response.Succeeded ? response.List : response;
}
export const RedeemCodes = async payload => {
  return await post(payload, "redeem/")
}
export const ScrambledRedeemCode = async scCode => {
  return await get(`redeem/sc/${scCode}`)
}
export const SendReceipt = async payload => {
  return await post(payload, "redeem/sendReceipt")
}
export const SendTaxReceipt = async payload => {
  return await post(payload, "redeem/sendTaxReceipt")
}
export const GetRedemptionPage = async userId => {
  return await get(`userRedemptionPage/${userId}`)
}
export const SetRedemptionPage = async (userId, payload) => {
  return await post(payload, `userRedemptionPage/${userId}`)
}
export const ClearCart = async () => {
  return await get(`shoppingCart/clear`)
}
export const AddToDonationCart = async (payload) => {
  return await post(payload, `shoppingCart/addToDonation`)
}
export const AddToDonation = async (payload) => {
  return await post(payload, `redeem/addToDonation`)
}
export const DeleteRedemptionPage = async userId => {
  return await get(`userRedemptionPage/delete/${userId}`)
}
export const GetRedeemCodeInfo = async code => {
  return await get(`redeem/${code}`)
  // process.env.REACT_APP_DEV_BUILD === "true" && console.log("get redemption code", r)
  // return r;
}
export const GetRedeemInfoFromServerSession = async () => {
  return await get("redeem/session")
}
export const SendDonorEmail = async () => {
  return await get("redeem/sendDonorEmail")
}
export const LandingPageHit = async qsVarsData => {
  return await post(qsVarsData, "generalUtils/landingPageHit")
},
  PageHit = async qsVarsData => {
    return await post(qsVarsData, "generalUtils/pageHit")
  },
  GetTitleAndMetaTags = async pageName => {
    const response = await get(`generalUtils/titleAndMetaTags/${pageName}`)
    return response.TitleAndMetaTags
  },
  GetCustomPhysicalCardTypes = async () => {
    const response = await get("purchaseGeneral/customPhysicalCardTypes")

    return response.Succeeded ? response.List : []
  },
  GetCustomPhysicalCards = async () => {
    const response = await get("shoppingcart/customPhysicalCards")
    return response.Succeeded ? response.List : []
  },
  GetDigitalCardOccasions = async hiddenOccasionId => {
    let url = "digitalCards/occasions"
    if (hiddenOccasionId) {
      url += `/${hiddenOccasionId}`
    }
    const response = await get(url)
    return response.Succeeded
      ? response.List.filter(o =>
        !o.IsCorporateLogo &&
        o.OccasionId !== 22, //Other Occasion
      )
      : []
  },
  GetDigitalCardSchemes = async () => {
    const response = await get("purchaseGeneral/digitalCardSchemes")
    return response.Succeeded ? response.List : []
  },
  GetDigitalCardsForOccasion = async (occasionId, hiddenOccasionId) => {
    let url = `purchaseGeneral/digitalCards/${occasionId.toString()}`

    if (
      hiddenOccasionId &&
      parseInt(hiddenOccasionId, 10) === parseInt(occasionId, 10)
    ) {
      // url += "/true"
    }
    const response = await get(url)
    return response.Succeeded ? response.List : []
  },
  GetSingleDigitalCardOccasion = async occasionId => {
    const response = await get(
      `purchaseGeneral/digitalCardOccasion/${occasionId.toString()}`,
    )
    return response.Succeeded ? response.OccasionInfo : {}
  },
  GetShippingTypes = async () => {
    const response = await get("purchaseGeneral/shippingTypes")
    return response.Succeeded ? response.List : []
  },
  GetGeneralGlobalData = async () => {
    const response = await get("generalUtils/generalGlobalData")
    return response?.Succeeded && response
  },
  GetCustomCardsShippingTypes = async () => {
    const response = await get("purchaseGeneral/shippingTypes/true")
    return response.Succeeded ? response.List : []
  },
  GetPrintLayouts = async () => {
    const response = await get("digitalCards/printLayouts")
    return response.Succeeded ? response.List : []
  },
  GetTotalDonated = async () => {
    const response = await get("purchaseGeneral/total-donated")
    return response.Succeeded ? response.List : []
  }
/**
 * Gets a single Shopping Cart Item
 * @param {number} donationIndex
 * @returns {{
 * ItemIndex:number,
 * Type:string,
 * ShoppingCartItemTypeId:ShoppingCartItemTypes,
 * Description:string,
 * ShortDescription:string,
 * Denomination:number,
 * Quantity:number,
 * DiscountPercent:number,
 * ShippingInfoText:string,
 * ShippingInfo:{ShippingInfoId:number,  ShippingFirstName:string,  ShippingLastName:string,  ShippingAttn:string,  ShippingAddress1:string,  ShippingAddress2:string,  ShippingCity:string,  ShippingState:string,  ShippingZip:string,  Email:string,  ShippingPhone:string,  Greeting:string,  Msg:string,  ShippingType:object,  CardUniqueId:string,  DefaultShippingUserId:number,  ShippingName:string,  ShippingTypeText:string,  ShippingTypeId:number,  ShippingCompanyId:number,  IsExpeditedShipping:boolean,  BaseShippingCost:number,  ExpeditedShippingCost:number,  MultipleShippingRefund:number,  HasFullShippingInfo:boolean,  ShippingIncompleteIssues:[string]},
 * ShipsToRecipient:boolean,
 * HasCompleteShippingInfo:string,
 * ShippingIncompleteIssues:[string],
 * RecipientEmailAddresses:[string],
 * RecipientPhoneNumbers::[string],
 * TotalForCards:number,
 * PaidItemId:number,
 * ItemImage:string,
 * RedemptionCodes:[string],
 * IsBulkHiddenAmount:boolean,
 * PurchaseItemInfo:object}}
 */
export const GetShoppingCartItem = async (donationIndex) => {
  if (Number.isInteger(parseInt(donationIndex)))
    return await get(`shoppingcart/item/${donationIndex}`)
}
export const GetGiftCardRetailers = async (onlyAcceptsEcode = true) => {
  const response = await get(
    `donateRetailCards/retailerList/${(!!onlyAcceptsEcode).toString()}`,
  )
  return response.Succeeded ? response.List : []
},
  GetFullCharityList = async (lastModified) => {
    const fromStorage = JSON.parse(localStorage?.getItem("charityList"))
    lastModified ??= await GetCharityListLastModified()
    const charityData = {
      lastUpdate: lastModified,
      list: [],
    }
    let isUpToDate = false
    if (
      fromStorage &&
      fromStorage.lastUpdate &&
      fromStorage.list &&
      fromStorage.list.length > 99
    ) {
      const lastUpdate = new Date(fromStorage.lastUpdate)
      isUpToDate = (lastUpdate.valueOf() === lastModified.valueOf())
    }
    if (isUpToDate) {
      charityData.list = fromStorage.list
      process.env.REACT_APP_DEV_BUILD === "true" &&
        console.log(
          "Retrieved charity list data from local storage. List last modified: " +
          lastModified,
        )
      return charityData.list
    }
    else {
      //If the list is not up to date, and the list is not currently loading, clear it befor we start loading.
      if (loadingCharityList.size === 0) {
        localStorage.removeItem("charityList");
      }

      let list = [];
      if (!loadingCharityList.has(1)) {
        list = await LoadCharitiesByRank(1, lastModified);
        process.env.REACT_APP_DEV_BUILD === "true" && console.log("Retrieved charity list data part 1.");
      }
      if (!loadingCharityList.has(2)) {
        list = await LoadCharitiesByRank(2, lastModified);
        process.env.REACT_APP_DEV_BUILD === "true" && console.log("Retrieved charity list data part 2.");
      }
      if (!loadingCharityList.has(0)) {
        //Loads all the rest of the charities - those with no rank or a rank above 2
        list = await LoadCharitiesByRank(0, lastModified);
        process.env.REACT_APP_DEV_BUILD === "true" && console.log("Retrieved charity list data part 3.");
      }
      return list;
    }
  },
  GetCharityListLastModified = async () => {
    return new Date(await GetDateCharityLastModified());
  },
  LoadCharitiesByRank = async (rankToLoad, lastModified) => {
    lastModified ??= new Date(await GetDateCharityLastModified())

    let currentList;

    const fromStorage = JSON.parse(localStorage?.getItem("charityList"))
    if (fromStorage) {
      currentList = fromStorage.list;
      const lastUpdate = new Date(fromStorage.lastUpdate)
      const isUpToDate = lastUpdate.valueOf() === lastModified.valueOf()
      if (currentList &&
        isUpToDate &&
        currentList.some(c =>
          //Check if the list contains any charities of the requested rank,
          //OR if the rank is 0 (or above 2), check if the list contains any charities that are not ranked 1 or 2
          (c.Rank === rankToLoad) ||
          ((!rankToLoad || rankToLoad === 0 || rankToLoad > 2) && (c.Rank !== 1 && c.Rank !== 2)))) {
        return currentList;
      }
    }

    //Assure that we are not already loading this part of the list
    if (loadingCharityList.has(rankToLoad)) return currentList;

    loadingCharityList.add(rankToLoad);
    let url = `charity/list/${rankToLoad || 3}`
    const response = await get(url)
    if (response?.Succeeded) {
      const charityData = {
        lastUpdate: lastModified,
        list: (!!currentList ? currentList.concat(response.List) : response.List)
      };
      localStorage.setItem("charityList", JSON.stringify(charityData))

      process.env.REACT_APP_DEV_BUILD === "true" && console.log(`Retrieved charity list data part ${rankToLoad} from server. List last modified: ${lastModified}`);

      //This part of the list is now loaded, so remove it from the loading list
      loadingCharityList.delete(rankToLoad);
      return charityData.list;
    }
    else {
      loadingCharityList.delete(rankToLoad);
    }
  },
  /**
   * Gets a list of Charities. Optionally filtered.
   * @param {[{CharityId:number,CharityName:string,CategoryId:number,CategoryName:string,ShortDescription:string,IsLocal:boolean,LogoURL:string,Locations:[string]}]} charityList the list of charities to search through
   * @param {"all"|"main"|"local"} listName optionally restrict to one of the lists. Default is 'all'
   * @param {number} categoryId  optionally restrict to one of the charity categories.
   * @param {string} searchTerm optionally filter for charities that have all the words of the search term in their name
   * @param {bool} includeDescription if using searchTerm, should the charity's short description be searched as well?
   * @returns {[{CharityId:number,CharityName:string,CategoryId:number,CategoryName:string,ShortDescription:string,IsLocal:boolean,LogoURL:string,Locations:[string]}]}
   * */
  GetCharityList = async (
    listName,
    categoryId,
    searchTerm,
    searchDescription,
  ) => {
    const fullList = await GetFullCharityList()
    return filterCharities(
      fullList,
      searchTerm,
      categoryId,
      listName,
      searchDescription,
    )
  },
  GetCharityInfo = async charityIdOrName => {
    const response = await get(`charity/${charityIdOrName}`)
    return response.Succeeded ? response.CharityInfo : {}
  },
  GetMultipleCharityInfos = async charityIds => {
    const response = await get(`charity/filter/${charityIds.toString()}`)
    return response.Succeeded ? response.List : []
  },
  SearchCharities = async (searchTerm, searchDescription) => {
    const fromStorage = JSON.parse(localStorage.getItem("charityList"))
    if (fromStorage && fromStorage.list && fromStorage.list.length > 100) {
      return filterCharities(
        fromStorage.list,
        searchTerm,
        null,
        "all",
        searchDescription,
      )
    }
    else {
      const response = await get(`charity/list/search/${searchTerm}`)
      return response.Succeeded ? response.List : []
    }
  },
  GetHiddenCharities = async categoryId => {
    const response = await get(`charity/list/all/${categoryId}/true`)
    return response.Succeeded ? response.List : []
  },
  GetCharityCategories = async () => {
    let categoryList = [];
    const fromStorage = JSON.parse(localStorage?.getItem("categoryList"))
    if (fromStorage && fromStorage.length > 1) {
      categoryList = fromStorage;
      process.env.REACT_APP_DEV_BUILD === "true" && console.log("Retrieved charity category list data from local storage.");
    }
    else {
      const response = await get("charity/cats/list")
      if (response.Succeeded) {
        categoryList = response.List;
        localStorage.setItem("categoryList", JSON.stringify(categoryList));
        process.env.REACT_APP_DEV_BUILD === "true" && console.log("Retrieved charity category list data from server.");
      }
    }
    return categoryList;
  },
  GetCharityCategoryCodes = async () => {
    const response = await get("charity/cats/list/code")
    return response.Succeeded ? response.List : []
  },
  GetRedemptionCodeOrders = async () => {
    const response = await get("shoppingcart/redemptionCodePurchases")
    return response.Succeeded ? response.List : []
  },
  RedemptionCodeOrders = async (payload) => {
    return await post(payload, "shoppingcart/redemptionCodePurchases")
  },
  GetDirectToCharityDonations = async () => {
    const response = await get("shoppingcart/directToCharityDonations")
    return response.Succeeded ? response.List : []
  },
  GetUserPreviousRecipients = async () => {
    const response = await get("account/recipientList")
    return response.Succeeded ? response.List : []
  },
  GetLoggedInUserApi = async () => {
    return await get("account/loggedInUser")
  },
  LoginUserApi = async login => {
    return await post(login, "account/login")
  },
  LogoutUserApi = async () => {
    return await get("account/logout")
  },
  CreateAccountApi = async info => {
    return await post(info, "account/create")
  },
  ForgotPasswordApi = async email => {
    return await post(email, "account/forgotPassword/")
  },
  CheckEmailAvailApi = async email => {
    const response = await get(`account/emailAvail/${email}`)
    return response.Succeeded && response.Available
  },
  GetCustomCharityListsApi = async () => {
    return await get("account/customCharityLists")
  },
  GetCustomCharityListsByIDApi = async id => {
    return await get(`account/customCharityLists/${id}`)
  },
  CreateCustomCharityListApi = async data => {
    return await post(data, "account/customCharityList")
  },
  SaveDefaultShippingInfoApi = async data => {
    return await post(data, "account/defaultShippingInfo")
  },
  UpdateCorpLogo = async data => {
    return await post(data, "account/corpLogo/update")
  },
  GetCartApi = async () => {
    return await get("shoppingcart")
  },
  GetCustomPhysicalCardsApi = async () => {
    return await get("shoppingcart/customPhysicalCards")
  },
  GetDirectToCharityDonationsApi = async () => {
    return await get("shoppingcart/directToCharityDonations")
  }

/**
 * Updates the supplied Physical Card on the session Shopping Cart
 * @param {{PhysicalCardTypeId:number,ShipsToRecipient:boolean,ShippingInfo:object}} data
 * @param {number} donationIndex
 * @returns {{Succeeded:boolean, ShipToMeDonations:[object], ShipToRecipientDonations:[object],ErrorMessage:string}}
 */
export async function UpdatePhysicalCardApi(data) {
  return await post(data, `shoppingcart/physicalCards`)
}

/**
 * Adds supplied Physical Card to session Shopping Cart
 * @param {{PhysicalCardTypeId:number,ShipsToRecipient:boolean,ShippingInfo:object}} data
 * @returns {{Succeeded:boolean, ShipToMeDonations:[object], ShipToRecipientDonations:[object],ErrorMessage:string}}
 */
export async function AddPhysicalCardsApi(data) {
  return await post(data, "shoppingcart/physicalCards")
}

export const AddCustomPhysicalCardsApi = async data => {
  return await post(data, "shoppingcart/customPhysicalCards")
},
  AddCustomPhysicalCardApi = async data => {
    return await post(data, "shoppingcart/addCustomPhysicalCard")
  },
  AddDirectToCharityDonationsApi = async data => {
    return await post(data, "shoppingcart/directToCharityDonations")
  },
  UpdateCustomPhysicalCardApi = async data => {
    return await post(data, `shoppingcart/addCustomPhysicalCard`)
  },
  RemoveFromCartApi = async index => {
    return await get("shoppingcart/remove/" + index.toString())
  },

  UpdateCartTipApi = async amount => {
    return await post(amount || "0", "shoppingcart/tip")
  },
  UpdateCardShippingInfoApi = async data => {
    return await post(
      data.shippingInfo,
      `shoppingcart/shippingInfo/${data.index}`,
    )
  },
  SetPreSelectedCharityIdApi = async charityId => {
    return await post(null, `shoppingcart/preSelectedCharity/${charityId}`)
  }

/**
 * Gets a list of available Physical Card Types with all their denominations
 * @returns {[{PhysicalCardTypeId:number, CardTypeName:string, ImageUrl:string, IsForGeneralList:boolean, Denominations:[{Denomination:number, Stock:number}], Stock:string, Description:string, OccasionId:number, IsOpenDenomination:boolean}]}
 **/
export async function GetPhysicalCardTypesApi(occasionId, hiddenOccasionIds) {
  const response = await get(
    `purchaseGeneral/physicalCardTypes/${occasionId || 0}${hiddenOccasionIds ? "/" + hiddenOccasionIds : ""
    }`,
  )
  return response.Succeeded ? response.List : response
}

export const GetCustomPhysicalCardTypesApi = async () => {
  const response = await get("purchaseGeneral/customPhysicalCardTypes")
  return response.Succeeded ? response.List : response
},
  GetPlasticPrinterEmailAddressApi = async () => {
    const response = await get("generalUtils/plasticPrinterEmailAddress")
    return response.Succeeded ? response.PlasticPrinterEmailAddress : response
  },
  GetPhysicalShippingTypesApi = async () => {
    const response = await get("purchaseGeneral/shippingTypes")
    return response.Succeeded ? response.List : response
  },
  GetCustomShippingTypesApi = async () => {
    const response = await get("purchaseGeneral/shippingTypes/true")
    return response.Succeeded ? response.List : response
  },
  GetDigitalCardOccasionsApi = async hiddenOccasionId => {
    let url = "purchaseGeneral/digitalCardOccasions"
    if (hiddenOccasionId) {
      url += `/${hiddenOccasionId}`
    }
    return await get(url)
  },
  GetDigitalCardSchemesApi = async () => {
    return await get("purchaseGeneral/digitalCardSchemes")
  },
  GetPrintLayoutsApi = async () => {
    return await get("purchaseGeneral/printLayouts")
  },
  GetGiftCardRetailersApi = async (onlyAcceptsEcode = true) => {
    return await get(
      `donateRetailCards/retailerList/${(!!onlyAcceptsEcode).toString()}`,
    )
  },
  PreCheckoutApi = async data => {
    return await post(data, "checkout/preCheckout")
  },
  CheckoutCorporateCheckApi = async data => {
    return await post(data, "checkout/CheckoutCorporateCheck")
  },
  CheckoutCreditCardApi = async data => {
    return await post(data, "checkout/CheckoutCreditCard")
  },
  PayPalExpressTokenApi = async (token, payerId) => {
    return await get(`checkout/PayPalExpress/${token}/${payerId}`)
  },
  CheckoutPayPalExpressApi = async () => {
    return await post(null, "checkout/CheckoutPayPalExpress")
  },
  CheckoutPayPalSDKApi = async (data) => {
    return await post({ ...data, isAddedDonation: !!data.isAddedDonation }, "checkout/CheckoutPayPalSDK")
  },
  ConfirmationEmailApi = async purchaseId => {
    return await get(`/paidOrder/confirmationEmail/${purchaseId}`)
  },
  RedemptionGuaranteeApi = async data => {
    return await post(data, `/paidOrder/redemptionGuarantee`)
  },
  GetRedemptionCodeCSVApi = async purchaseId => {
    return await get(`/paidOrder/redemptionCodesCsv/${purchaseId}`)
  },
  GetPaidOrder = async () => {
    return await get(`/paidOrder`)
  },
  ContactApi = async data => {
    return await post(data, "contact")
  },
  RegisterLocalCharityApi = async data => {
    return await post(data, "charity/localCharityRegister")
  },
  GetCharityCategoryCodesApi = async () => {
    return await get("charity/cats/list/code")
  },
  GetCharityCategoriesApi = async () => {
    return await get("charity/cats/list")
  },
  GetMainCharities = async () => {
    return (await GetFullCharityList()).filter(c => !c.IsLocal)
  },
  GetLocalCharities = async () => {
    return (await GetFullCharityList()).filter(c => c.IsLocal)
  },
  GetDateCharityLastModified = async () => {
    const response = await get("/charity/list/dateLastModified")
    return response?.Succeeded && response.LastModified
  },
  DonateRetailCard = async data => {
    return await post(data, "donateRetailCards/donate")
  },
  // GetCharityList = async (listName, categoryId) => {
  //   let url = 'charity/list/' + (listName || 'all');
  //   if (categoryId) {
  //     url += '/' + categoryId.toString();
  //   }
  //   const response = await get(url);
  //   return response.Succeeded ? response.List : [];
  // },
  // GetMultipleCharityInfos = async charityIds => {
  //   const response = await get(`charity/filter/${charityIds.toString()}`);
  //   return response.Succeeded ? response.List : [];
  // },
  // SearchCharities = async searchTerm => {
  //   const response = await get(`charity/search/${searchTerm}`);
  //   return response.Succeeded ? response.List : [];
  // },
  DefaultDigitalCardId = 158, //the white riibbon card
  DefaultDigitalCard = {
    CardId: DefaultDigitalCardId,
    CardName: "Charity Choice Card-  Flat",
    HasCustomSection: false,
    CustomSectionDefaultHtml: null,
    OccasionId: 23,
    OccasionName: "CharityChoice Gift Card",
    ImageUrl: `https://res.cloudinary.com/charity-choice/eCards/${DefaultDigitalCardId}.jpg`,
  },
  DefaultDigitalCardOccasion = {
    OccasionId: 23,
    OccasionName: "CharityChoice Gift Card",
    IsCustomImage: false,
    IsPopular: false,
  },
  DefaultDigitalCardLayout = {
    ColorSchemes: [
      {
        SchemeName: "Elegant_Gray",
        SchemeText: "Elegant Gray",
        BackgroundColor: "73726D",
      },
      { SchemeName: "Gold", SchemeText: "Gold", BackgroundColor: "FFD356" },
      { SchemeName: "Green", SchemeText: "Green", BackgroundColor: "007515" },
      {
        SchemeName: "Holiday_Red",
        SchemeText: "Holiday Red",
        BackgroundColor: "C0180D",
      },
      {
        SchemeName: "Soft_White",
        SchemeText: "Soft White",
        BackgroundColor: "fafafa",
      },
      {
        SchemeName: "Torquoise",
        SchemeText: "Torquoise",
        BackgroundColor: "509BB8",
      },
    ],
    Description: "One Sided - Full Page",
    IsTwoSided: false,
    LayoutId: 0,
  },
  DefaultDigitalCardScheme = {
    SchemeName: "Soft_White",
    SchemeText: "Soft White",
    BackgroundColor: "fafafa",
  },
  DeliveryMethods = Object.freeze({
    Email: 1,
    Text: 2,
    Social: 3,
    Print: 4,
  }),
  AccountTypes = Object.freeze({
    Personal: 0,
    Corporate: 1,
    Charity: 2,
    Wedding: 3,
    Birthday: 4,
    SpecialEvent: 5,
    Special: 6,
    GivingBuddy: 7,
    Redemption: 8,
    Reward: 10,
  }),
  CardTypes = Object.freeze({
    Digital: 1,
    Print: 2,
    Honor: 3,
  }),
  ShoppingCartItemTypes = Object.freeze({
    PhysicalCard: 0,
    DigitalCharityCards: 1,
    PrintableDonationCards: 2,
    RedemptionCodePurchase: 3,
    DirectToCharityDonation: 4,
    AmericanExpressBrandedRewardCards: 5,
    VisaPrepaidCard: 6,
    CustomPhysicalCard: 7,
    HonorCardToEmail: 8,
    HonorCardToPrint: 9,
    FacebookCard: 10,
    GiftCardExchange: 11,
    APICardActivation: 12,
    ManualActivation: 13,
    ExternalCodePurchase: 14,
    Unknown: 15,
    AddToDonation: 16,
  }),
  EditUrls = Object.freeze({
    [ShoppingCartItemTypes.PhysicalCard]: item =>
      item && item.PurchaseItemInfo && item.PurchaseItemInfo.ShipsToRecipient
        ? `/Purchase/ShipToRecipient/AddRecipientCards.aspx?DI=${item.ItemIndex}`
        : `/Purchase/PhysicalCards/ShipToDonor${!!item ? "/?SG=1&DI=" + item.ItemIndex : ""
        }`,
    [ShoppingCartItemTypes.DigitalCharityCards]: item =>
      item.IsBulkHiddenAmount
        ? `/Purchase/DigitalCards/?ICH=1&DI=${item.ItemIndex}`
        : `/Purchase/DigitalCards/?DI=${item.ItemIndex}`,
    [ShoppingCartItemTypes.PrintableDonationCards]: item =>
      `/Purchase/PrintCards/?DI=${item.ItemIndex}`,
    [ShoppingCartItemTypes.RedemptionCodePurchase]: item =>
      item.IsBulkHiddenAmount
        ? `/Purchase/RedemptionCodes/?ICH=1&DI=${item.ItemIndex}`
        : `/Purchase/RedemptionCodes/?DI=${item.ItemIndex}`,
    [ShoppingCartItemTypes.DirectToCharityDonation]: item =>
      `/Purchase/DirectToCharityDonation/?DI=${item.ItemIndex}`,
    [ShoppingCartItemTypes.AmericanExpressBrandedRewardCards]: null,
    [ShoppingCartItemTypes.VisaPrepaidCard]: null,
    [ShoppingCartItemTypes.CustomPhysicalCard]: item =>
      `/Purchase/CustomPhysicalCards${!!item ? "/?DI" + item.ItemIndex : ""}`,
    [ShoppingCartItemTypes.HonorCardToEmail]: item =>
      `/Purchase/HonorCards/?DI=${item.ItemIndex}`,
    [ShoppingCartItemTypes.HonorCardToPrint]: item =>
      `/Purchase/HonorCards/?DI=${item.ItemIndex}`,
    [ShoppingCartItemTypes.FacebookCard]: null,
    [ShoppingCartItemTypes.GiftCardExchange]: null,
    [ShoppingCartItemTypes.APICardActivation]: null,
    [ShoppingCartItemTypes.ManualActivation]: null,
    [ShoppingCartItemTypes.ExternalCodePurchase]: null,
    [ShoppingCartItemTypes.Unknown]: null,
  }),
  ImageUrls = Object.freeze({
    [ShoppingCartItemTypes.PhysicalCard]:
      "/images/PhysicalCardsSmall.jpg?height=50",
    [ShoppingCartItemTypes.DigitalCharityCards]: "/images/ecards.gif",
    [ShoppingCartItemTypes.PrintableDonationCards]:
      "/images/CustomizedCertificatesSmall.jpg?height=50",
    [ShoppingCartItemTypes.RedemptionCodePurchase]: "/images/icon.jpg",
    [ShoppingCartItemTypes.DirectToCharityDonation]:
      "/images/DirectToCharityDonation.png",
    [ShoppingCartItemTypes.AmericanExpressBrandedRewardCards]: null,
    [ShoppingCartItemTypes.VisaPrepaidCard]: null,
    [ShoppingCartItemTypes.CustomPhysicalCard]:
      "/images/CustomPhysicalCards/Ribbon.png",
    [ShoppingCartItemTypes.HonorCardToEmail]: "/images/ecards.gif",
    [ShoppingCartItemTypes.HonorCardToPrint]: "/images/ecards.gif",
    [ShoppingCartItemTypes.FacebookCard]: null,
    [ShoppingCartItemTypes.GiftCardExchange]: "/images/DonateRetailCard.png",
    [ShoppingCartItemTypes.APICardActivation]: null,
    [ShoppingCartItemTypes.ManualActivation]: null,
    [ShoppingCartItemTypes.ExternalCodePurchase]: null,
    [ShoppingCartItemTypes.Unknown]: null,
  }),
  ShippingFormTypes = Object.freeze({
    All: 0,
    Physical: 1,
    Custom: 2,
    ToRecipient: 3,
  }),
  PhysicalCardTypes = Object.freeze({
    ToMe: 1,
    ToRecipient: 2,
    Custom: 3,
  }),
  DonateRetailCardsMethods = Object.freeze({
    MailIn: 1,
    Online: 2,
  }),
  CharityListTypes = Object.freeze({
    Default: 1,
    Animals: 2,
    CharityChoice: 3,
    Custom: 4,
  }),
  PaymentTypes = Object.freeze({
    Paypal: 1,
    CreditCard: 2,
    Corporate: 3,
    PayPalSDK: 4,
  }),
  StatesList = [
    { value: "AL", label: "Alabama" },
    { value: "AK", label: "Alaska" },
    { value: "AB", label: "Alberta" },
    { value: "AS", label: "American Samoa" },
    { value: "AZ", label: "Arizona" },
    { value: "AR", label: "Arkansas" },
    { value: "AA", label: "Armed Forces Americas" },
    { value: "AE", label: "Armed Forces Canada/Europe/Middle East/Africa" },
    { value: "AP", label: "Armed Forces Pacific" },
    { value: "BC", label: "British Columbia" },
    { value: "CA", label: "California" },
    { value: "CO", label: "Colorado" },
    { value: "CT", label: "Connecticut" },
    { value: "DE", label: "Delaware" },
    { value: "DC", label: "District of Columbia" },
    { value: "FL", label: "Florida" },
    { value: "GA", label: "Georgia" },
    { value: "GU", label: "Guam" },
    { value: "HI", label: "Hawaii" },
    { value: "ID", label: "Idaho" },
    { value: "IL", label: "Illinois" },
    { value: "IN", label: "Indiana" },
    { value: "IA", label: "Iowa" },
    { value: "KS", label: "Kansas" },
    { value: "KY", label: "Kentucky" },
    { value: "LA", label: "Louisiana" },
    { value: "ME", label: "Maine" },
    { value: "MB", label: "Manitoba" },
    { value: "MH", label: "Marshall Islands" },
    { value: "MD", label: "Maryland" },
    { value: "MA", label: "Massachusetts" },
    { value: "MI", label: "Michigan" },
    { value: "FM", label: "Micronesia" },
    { value: "MN", label: "Minnesota" },
    { value: "UM", label: "Minor Outlying Islands" },
    { value: "MS", label: "Mississippi" },
    { value: "MO", label: "Missouri" },
    { value: "MT", label: "Montana" },
    { value: "NE", label: "Nebraska" },
    { value: "NV", label: "Nevada" },
    { value: "NB", label: "New Brunswick" },
    { value: "NH", label: "New Hampshire" },
    { value: "NJ", label: "New Jersey" },
    { value: "NM", label: "New Mexico" },
    { value: "NY", label: "New York" },
    { value: "NL", label: "Newfoundland and Labrador" },
    { value: "NC", label: "North Carolina" },
    { value: "ND", label: "North Dakota" },
    { value: "MP", label: "Northern Mariana Islands" },
    { value: "NT", label: "Northwest Territories" },
    { value: "NS", label: "Nova Scotia" },
    { value: "NU", label: "Nunavut" },
    { value: "OH", label: "Ohio" },
    { value: "OK", label: "Oklahoma" },
    { value: "ON", label: "Ontario" },
    { value: "OR", label: "Oregon" },
    { value: "PW", label: "Palau" },
    { value: "PA", label: "Pennsylvania" },
    { value: "PE", label: "Prince Edward Island" },
    { value: "PR", label: "Puerto Rico" },
    { value: "QC", label: "Quebec" },
    { value: "RI", label: "Rhode Island" },
    { value: "SK", label: "Saskatchewan" },
    { value: "SC", label: "South Carolina" },
    { value: "SD", label: "South Dakota" },
    { value: "TN", label: "Tennessee" },
    { value: "TX", label: "Texas" },
    { value: "UT", label: "Utah" },
    { value: "VT", label: "Vermont" },
    { value: "VI", label: "Virgin Islands" },
    { value: "VA", label: "Virginia" },
    { value: "WA", label: "Washington" },
    { value: "WV", label: "West Virginia" },
    { value: "WI", label: "Wisconsin" },
    { value: "WY", label: "Wyoming" },
    { value: "YT", label: "Yukon" },
  ],
  LayoutList = [
    { value: "CardSize", label: "One Sided - Card Size" },
    { value: "FullPage", label: "One sided - Full Page" },
  ],
  DeliveryMethodList = [
    { value: DeliveryMethods.Email, label: "Email" },
    { value: DeliveryMethods.Text, label: "Text Message" },
    { value: DeliveryMethods.Social, label: "Share via messaging" },
    { value: DeliveryMethods.Print, label: "Print on your own" },
  ],
  AccountTypeList = [
    { value: "0", label: "Personal Account" },
    { value: "1", label: "Corporate and Business" },
    { value: "5", label: "Redemption" },
  ],
  ContactTypeList = [
    { value: 0, label: "Suggestion" },
    { value: 1, label: "Promotional Cause Programs" },
    { value: 2, label: "Corporate Customization" },
    { value: 3, label: "Inquiry" },
    { value: 4, label: "I am having a problem with this site" },
    { value: 5, label: "Complaint" },
    { value: 6, label: "Order Gift Cards Assistance" },
    { value: 7, label: "Other" },
  ],
  TryingTypeList = [
    { value: "Purchase Gift Cards", label: "Purchase Gift Cards" },
    { value: "Redeem A Gift Card", label: "Redeem A Gift Card" },
    {
      value: "Order CharityChoice-branded Gift and Reward Cards",
      label: "Order CharityChoice-branded Gift and Reward Cards",
    },
    { value: "Other", label: "Other" },
  ],
  ToolTips = {
    SpecialKidsFund:
      "This website is a fundraising project of our 501c3 umbrella organization for special needs kids. We support special needs schools, camp programs, youth groups, et. and run a nationwide 'donate a wheelchair van' program, giving the vans for no charge to needy families.",
  },
  SmallSliderItems = [
    {
      src: small_slider1,
      altText:
        "American Cancer Society and other charities are registered with CharityChoice for gift-recipients to select.",
      caption: "",
    },
    {
      src: small_slider2,
      altText: "Alzeimer’s Association and USO helping elderly and veterans receive funding via our portal for charitable giving.",
      caption: "",
    },
    {
      src: small_slider3,
      caption: "",
    },
    {
      src: small_slider4,
      caption: "",
    },
    {
      src: small_slider5,
      caption: "",
    },
    {
      src: small_slider6,
      caption: "",
    },
  ],
  BigSliderItems = [
    {
      src: big_slider1,
      altText:
        "American Cancer Society. Worthwhile causes; help cure diseases, prevent animal abuse, protect the environment, assist disaster relief and feed the hungry. Feeding America.",
      caption: "",
    },
    {
      src: big_slider2,
      caption: "",
    },
    {
      src: big_slider3,
      caption: "",
    },
  ],
  ImgCustomCards = {
    Ribbon: custom_ribbon,
    Holiday: custom_holiday,
    "Fully Custom": custom_full,
  },
  STEPPER_PARAMS = {
    autoHeight: true,
    noSwiping: true,
  },
  RedeemStepTitles = [
    "Step 1 - Enter your redemption code",
    "Welcome to CharityChoice redemption",
    "Step 3 - Select your charities",
    "Step 4 - Confirm before Redeem",
    "Thanks!",
  ],
  PurchasePhysicalCardStepTitles = [
    "Choose a Card Type",
    "Choose a Card Style",
    "Enter Denomination & Quantity",
  ]
