import { React, useState } from "react"
import { Button } from "../../.."
import { useRedemptionData } from "../../../../Context/redemption"
import styles from "./styles.module.scss"
import { useUserData } from "../../../../Context/user"
import Modal from "../../../Shared/Modal"

function Step0({ onContinue, hasExisting }) {
  const { redemptionPageData, setRedemptionPageData, deleteRedemptionPage } =
    useRedemptionData()
  const { loggedInUser } = useUserData()
  const { hasLogo } = redemptionPageData
  const [isModalOpen, setModalOpen] = useState(false)
  const isEditMode = JSON.parse(localStorage.getItem("isEditMode"))

  const setHasLogo = hasLogo => {
    setRedemptionPageData({
      ...redemptionPageData,
      hasLogo,
    })
  }

  const deleteExisting = async () => {
    if (await deleteRedemptionPage(loggedInUser.UserId)) {
      window.location = "/Account"
    }
  }
  const handleClickNoLogo = () => {
    if (!hasLogo) return
    if (!redemptionPageData?.HeaderImageURL) {
      setHasLogo(false)
    } else {
      setModalOpen(true)
    }
  }

  const handleRemoveImage = async () => {
    await setRedemptionPageData(prevData => ({
      ...prevData,
      HeaderImageURL: "",
      hasLogo: false,
    }))
    setModalOpen(false)
  }

  return (
    <div className={styles.container}>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        contentLabel="Action dialog">
        <p className="fs-4">
          Are you sure you do not want your logo displayed? Your exisiting logo
          will be removed.
        </p>
        <button
          className={styles.yesBtn}
          onClick={() => {
            handleRemoveImage()
          }}>
          Yes
        </button>
        <button className={styles.noBtn} onClick={() => setModalOpen(false)}>
          No
        </button>
      </Modal>
      {!!hasExisting ? (
        <div className={styles.divRemoveExisting}>
          You have an existing redemption page.
          <button onClick={deleteExisting}>
            Remove my existing redemption page
            <i className="fa fa-trash" />
          </button>
          You can edit your existing redemption page below.
        </div>
      ) : (
        <></>
      )}
      <div className={styles.title}>
        {isEditMode
          ? "Let's edit your redemption page!"
          : "How would you like to customize your redemption page?"}
      </div>
      <div className={styles.typeSelector}>
        <Button
          className={styles.btn}
          outline
          color={hasLogo ? "yellow" : "red"}
          onClick={() => setHasLogo(true)}>
          {redemptionPageData.HeaderImageURL
            ? "I want to change my logo"
            : "I have an image or logo to upload"}
        </Button>
        <div className={styles.space}></div>
        <Button
          className={styles.btn}
          outline
          color={hasLogo ? "red" : "yellow"}
          onClick={() => handleClickNoLogo()}>
          I do not have an image or logo to upload
        </Button>
      </div>
      <Button className={styles.btnContinue} onClick={onContinue}>
        Let's Continue
      </Button>
    </div>
  )
}

export default Step0
