import React, { useEffect } from "react";
import { Helmet } from "react-helmet"
import { Row, Col, Container } from "reactstrap";
import MainPage from "../../Components/Shared/MainPage";
import CharitiesInCategorySlider from "../../Components/Charities/CharitiesInCategorySlider";
import PhysicalCardIcon from "../../images/physical-card-icon.svg"
import GiftCardCustomIcon from "./../../images/gift-card-custom.svg"
import DigitalCardIcon from "./../../images/digital-card-icon.svg"
import PrintCardIcon from "./../../images/print-card.svg"
import HonorCardIcon from "./../../images/honor-card-icon.svg"
import RedemptionCodesIcon from "./../../images/redemption-codes.svg"
import DonateIcon from "./../../images/donate.svg"
import DonateBalanceIcon from "./../../images/donate-balance.svg"
import Carousel from "../../Components/Carousel";
import slideImage1 from "./SlideImages/1.jpg";
import slideImage2 from "./SlideImages/2.jpg";
import slideImage3 from "./SlideImages/3.jpg";
import slideImage4 from "./SlideImages/4.jpg";
import slideImage5 from "./SlideImages/5.jpg";
import styles from "./styles.module.scss"


export default function SupportIsraelFoodCard() {

  const charityInfo = {
    CharityId: 2432,
    CharityName: "Israel National Food Card",
    CategoryId: 18,
    CategoryName: "Jewish and Israel"
  };

  useEffect(() => {
    document.title = `Support ${charityInfo.CharityName}`
  }, []);

  const sliderItems = [
    {
      src: slideImage1,
      altText:
        "American Cancer Society and other charities are registered with CharityChoice for gift-recipients to select.",
      caption: "",
    },
    {
      src: slideImage2,
      altText: "Alzeimer's Association and USO helping elderly and veterans receive funding via our portal for charitable giving.",
      caption: "",
    },
    {
      src: slideImage3,
      caption: "",
    },
    {
      src: slideImage4,
      caption: "",
    },
    {
      src: slideImage5,
      caption: "",
    }
  ];
  return (<>
   <Helmet>
      <meta name="description" content="Gift recipients will be able to select from our curated list of nonprofits in Israel and the usa, engaging your friends and family in the process of supporting Israel and Jewish charities in the USA. Use Tzedakah as your gift giving. Bar/Bat Mitzvah, birthdays, sympathy and anniversaries." />      
      <title>Support Israeli and Jewish causes with your donation purchase of our charity gift cards</title>
    </Helmet>
    <MainPage
      fluid={true}
      title={`Support ${charityInfo.CharityName} and ${charityInfo.CategoryName} Causes`}
      description={
        <Container>
          <Row>
            <Col>
              <p>
                <strong>Food4Israel</strong> <strong>J</strong>ewish <strong>A</strong>ssistance and <strong>R</strong>elief - Support Israel, Support Families, Support the Elderly
              </p>
              <p>
                <strong className={styles.chanukah}>CHANUKAH CAMPAIGN AND HOLIDAY ASSISTANCE</strong>
                <strong> Now, the need for families and children is even greater.</strong>
              </p>
              <p>
                Help 10,000 families celebrate. Your donation will bring sustenance and joy!
              </p>
              <p>
                <em>He has told you, O man, what is good! What does your G-d ask of you, that you do justice, love loving kindness, and walk humbly with your G-d.</em> - Micah 6:8
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Carousel sliderItems={sliderItems} />
            </Col>
          </Row>
        </Container>
      }>
      <div className="section-title-row">
        <h2>Support Israeli and Jewish Causes</h2>
      </div>
      <section>
        <Container>
          <Row className="justify-content-between">
            <SingleItem
              caption="Honor Cards"
              icon={HonorCardIcon}
              iconAlt="CharityChoice Honor Cards"
              text={`Make a donation to ${charityInfo.CharityName} "in honor of" your recipient.`}
              url={`/Purchase/HonorCards/?CharityId=${charityInfo.CharityId}`}
            />
            <SingleItem
              caption="Donate Directly"
              icon={DonateIcon}
              iconAlt="Donate to charity"
              text={`Use your credit card to donate through us to ${charityInfo.CharityName}`}
              url={`/Purchase/DirectToCharityDonation/?CHID=${charityInfo.CharityId}`}
            />
            <SingleItem
              caption="Donate Gift Card Balance"
              icon={DonateBalanceIcon}
              iconAlt="Donate Gift Card Balance"
              text={`Use your retail gift card balance to make a donation to ${charityInfo.CharityName}.`}
              url={`/Purchase/DonateYourCard/?CID${charityInfo.CharityId}`}
            />
          </Row>
        </Container>
      </section>
      <div className="section-title-row">
        <h2>{`Support ${charityInfo.CategoryName} and other causes that resonate with you`}</h2>
      </div>
      <section>
        <Container>
          <Row className="text-center pb-5">
            <Col>
              Support Israeli and Jewish causes with your purchase of our charity gift cards.
              <br />
              Gift recipients will be able to select from our curated list of nonprofits, engaging your friends and family in the process.
            </Col>
          </Row>
        </Container>
        <CharitiesInCategorySlider categoryId={charityInfo.CategoryId} categoryName={charityInfo.CategoryName} />
        <Container>
          <Row className="justify-content-between mt-5">
            <SingleItem
              caption="Physical Cards"
              icon={PhysicalCardIcon}
              iconAlt="Buy the best charity gift cards for friends and clients. Holiday, Birthday, Seasons Greetings and Christmas Stocking Stuffers."
              text="Gift cards with one of our CharityChoice occasion designs; holiday, birthday, flower or charity ribbons."
              url="/Purchase/PhysicalCards/?SOC=Jewish"
              buttonText="Give Physical Cards"
            />
            <SingleItem
              caption="Digital Cards"
              icon={DigitalCardIcon}
              iconAlt="Send your best last-minute eGifts and stocking stuffers as eCards, text messages or post directly to friend's platform - Facebook, Twitter etc."
              text="Select a card to share by email, text, or social media. Last-minute gift idea; send now or later."
              url="/Purchase/DigitalCards/?SOC=Jewish"
              buttonText="Give Digital Cards"
            />
            <SingleItem
              caption="Print Your Own Cards"
              icon={PrintCardIcon}
              iconAlt="Print Your Own Cards. Donate in lieu of holiday gifts this year."
              text="Receive a high-quality PDF card to print at home."
              url="/Purchase/PrintCards/?SOC=Jewish"
              buttonText="Give Printable Cards"
            />
          </Row>
          <Row className="justify-content-between">
            <SingleItem
              caption="Custom Gift Cards"
              icon={GiftCardCustomIcon}
              iconAlt="Donate to purchase charity gift cards. Glossy Custom physical and Digital eGifts. Tax deductible, the best gifting idea."
              text={
                <>
                  Corporate and Events: Customize bulk physical cards
                  <br />
                  with your logo or your own, branded artwork.
                </>
              }
              url="/Purchase/PhysicalCards?type=3&SOC=Jewish"
              buttonText="Give Custom Cards"
            />
            <SingleItem
              caption="Redemption Codes"
              icon={RedemptionCodesIcon}
              iconAlt="CSV file of redemption codes for clients. Redeem gift card for top, highly rated charities. Best nonprofits - local, national and International."
              text={
                <>
                  Buy unique codes to download and distribute
                  <br />
                  using your own materials via any method you wish.
                </>
              }
              url="/Purchase/RedemptionCodes/?SOC=Jewish"
              buttonText="Buy Redemption Codes"
            />
            <SingleItem
              caption="Honor Cards"
              icon={HonorCardIcon}
              iconAlt="CharityChoice Honor Cards. Give to charity as my Holiday gift."
              text='Make a donation to the charity of your choice "in honor of" your recipient.'
              url={`/Purchase/HonorCards/?CharityId=${charityInfo.CharityId}`}
              buttonText="Give Honor Cards"
            />
          </Row>
          <Row className="justify-content-center">
            <SingleItem
              caption="Donate Directly"
              icon={DonateIcon}
              iconAlt="Donate to charity. Donate in lieu of Christmas gifts this year."
              text="eGifts that are giving back for friends and business."
              url={`/Purchase/DirectToCharityDonation/?CHID=${charityInfo.CharityId}`}
              buttonText="Donate Directly"
            />
            <SingleItem
              caption="Donate Gift Card Balance"
              icon={DonateBalanceIcon}
              iconAlt="Donate Gift Card Balance. Give to charity as my Christmas gift during COVID 19."
              text="Use your retail gift card balance to make a donation to a charity."
              url={`/Purchase/DonateYourCard/?CID${charityInfo.CharityId}`}
              buttonText="Donate Balance"
            />
          </Row>
        </Container>
      </section>
    </MainPage>
  </>
  );
}

function SingleItem({ caption, icon, iconAlt, text, url, onClick, buttonText }) {
  return (
    <Col xs="12" lg="4" className={styles.singleItem} key={caption}>
      <a href={url} onClick={onClick}>
        <div className={styles.description}>
          <img
            className={styles.icon}
            src={icon}
            alt={iconAlt}
          />
          <div className={styles.itemDetail}>
            <div className={styles.caption}>{caption}</div>
            <div className={styles.text}>{text}</div>
          </div>
        </div>
        {!!buttonText &&
          <div className={styles.link}>
            <a href={url} onClick={onClick}>{buttonText}</a>
          </div>
        }
      </a>
    </Col>
  )
}