import React, { useState, useEffect } from "react"
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Input, NavLink, UncontrolledDropdown } from "reactstrap"
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom"
import MainMenu from "../../../Common/MainMenu"
import { useUserData } from "../../../../Context/user"
import { useCartData } from "../../../../Context/cart"
import { GetRedeemCodeInfo, RedeemCodes } from "../../../../Code/Data"
import LoginModal from "../../../Common/LoginModal"
import { useCommonRedemptionEffect } from "../../Hooks/commonRedemptionEffect"
import styles from "./styles.module.scss"
import { useAppData } from "../../../../Context/appData"

export default function RedBarFullSize({ style, children, showFreeHolidayShipping }) {
  const history = useHistory()
  const [redeemCode, setRedeemCode] = useState("")
  const [submitted, setSubmitted] = useState()
  const [openLoginModal, setOpenLoginModal] = useState()
  const [directRedemption, setDirectRedemption] = useState(false)
  const [redeemInfo, setRedeemInfo] = useState("")
  const [codeValid, setCodeValid] = useState(false)
  const { fullCharityList } = useAppData()
  const [showSuccess, setShowSuccess] = useState(false)
  const { loggedInUser, logout, setUser } = useUserData()
  const { loading, cart } = useCartData()
  const toastId = React.useRef(null);

  const warnToast = message => {
    toast.dismiss(toastId.current || undefined);

    if (!!message) {
      toastId.current = toast.error(message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 7000,
      })
    }
  }

  useCommonRedemptionEffect(redeemCode, setCodeValid, setRedeemInfo, setDirectRedemption, setSubmitted, warnToast)

  useEffect(() => {
    async function handleDirectRedemption() {
      if (directRedemption) {
        await RedeemDirectlyToCharity()
      }
    }
    handleDirectRedemption()
  }, [directRedemption])

  const handleChangeRedeemCode = ({ target }) => {
    setRedeemCode(target.value)
  }

  const RedeemDirectlyToCharity = async () => {
    const selectedCharity = fullCharityList.find(({ CharityId }) => CharityId === redeemInfo.CharityId)
    const payload = {
      RedeemCode: redeemCode,
      Charities: selectedCharity ? [{ CharityId: selectedCharity.CharityId }] : [],
      ShareRedeemerInfo: redeemInfo?.ShowDonor,
    }
    payload.Charities[0].Amount = +redeemInfo.AmountToRedeem
    const { Succeeded, ErrorMessage } = await RedeemCodes(payload)
    if (Succeeded) {
      setShowSuccess(true)
    }
    else {
      warnToast(ErrorMessage)
    }
  }

  const handleSuccessLogin = user => {
    setOpenLoginModal(false)
    setUser(user)
  }

  const redeemKeyDown = event => {
    if (event.key === "Enter") {
      handleRedeem()
    }
  }

  const handleRedeem = async () => {
    toast.done(toastId);
    toast.dismiss(toastId);

    if (!redeemCode) {
      warnToast(null);
      history.push("/Redeem");
    }
    const { Succeeded, ErrorMessage, RedeemInfo } = await GetRedeemCodeInfo(redeemCode)
    setRedeemInfo(RedeemInfo)

    // Handle direct charity redemption
    if (RedeemInfo?.HasCharityId) {
      setDirectRedemption(true)
      return
    }
    setSubmitted(true)

    if (!Succeeded) {
      warnToast(ErrorMessage)
      return false
    }
    if (RedeemInfo.HasCustomRedemptionPage && !!RedeemInfo.RedemptionPageURL) {
      window.location = RedeemInfo.RedemptionPageURL
    }
    else if (RedeemInfo && RedeemInfo.RedemptionPageURL &&
      RedeemInfo.RedemptionPageURL.toUpperCase().indexOf("/pages/CustomRedemptionPages/".toUpperCase()) > -1) {
      window.location = RedeemInfo.RedemptionPageURL
    }
    else {
      warnToast(null);
      history.push(`/Redeem/?RC=${redeemCode.toUpperCase()}`)
    }
  }

  if (children) {
    return (
      <div className={styles.customContent} style={style}>
        {children}
      </div>
    )
  }

  const cartItems = !loading && cart && cart.Items && cart.Items.reduce((a, b) => a + b.Quantity, 0)

  return (
    <div className={styles.container}>
      <div className={styles.leftContent}>
        <div className={styles.mainMenu}>
          <MainMenu />
        </div>
        {!!cartItems &&
          <a href="/Purchase/ShoppingCart" className="nav-link">
            <div className={styles.linkCart}>
              <div className={styles.cartBadge}>
                {cartItems}
              </div>
              <i className="fa fa-shopping-cart" />
              <span>Cart</span>
            </div>
          </a>
        }
        {loggedInUser ? (
          <UncontrolledDropdown>
            <DropdownToggle nav>
              <div className={styles.linkLogin}>
                <i className="fa-regular fa-user"></i>
                <span className="p-2">Hi {loggedInUser.FirstName}!</span>
              </div>
            </DropdownToggle>
            <DropdownMenu end>
              <DropdownItem href="/Account/">
                <i className="fas fa-cog text-primary" />
                My Account
              </DropdownItem>
              <DropdownItem href="/Account/Orders">
                <i className="fas fa-cog text-primary" />
                My Orders
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem>
                <NavLink onClick={logout}>
                  <i className="fas fa-sign-out-alt text-primary" />
                  Sign Out
                </NavLink>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        ) : (
          <NavLink className={styles.linkLogin} onClick={() => setOpenLoginModal(true)}>
            <i className="fa-regular fa-user"></i>Login
          </NavLink>
        )}
        <LoginModal
          isOpen={openLoginModal}
          caption="Log In"
          onClose={() => setOpenLoginModal(false)}
          onSuccess={handleSuccessLogin}
        />
      </div>
      <div className={styles.rightContent}>
        <div className={styles.redeemContent}>
          <Input
            type="text"
            placeholder="Enter Card Code"
            className={styles.inputRedeemCode}
            onKeyDown={redeemKeyDown}
            onChange={(event) => handleChangeRedeemCode(event)}
          />
          <div className={styles.btnRedeemContent}>

            <button className={styles.btnRedeem} onClick={handleRedeem}>
              Redeem Card
            </button>
            <a
              className={styles.linkMultiRedeem}
              href={`/Redeem/multi/?RC=${redeemCode}`}>
              Redeem Multiple Codes
            </a>
          </div>
        </div>
        <a className={styles.btnGiveGift} href="/#GiveGiftCards">
          Give Gift Card
        </a>
      </div>
      {showFreeHolidayShipping && (
        <Col
          xs="12"
          md="2"
          className="text-center justify-content-center small text-uppercase">
          <strong style={{ color: "#c44042" }}>FREE PRIORITY SHIPPING</strong>
        </Col>
      )}
    </div>
  )
}