import React, { useState } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";

export default function CarouselComponent({ sliderItems }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const onExiting = () => setAnimating(true);
  const onExited = () => setAnimating(false);
  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === sliderItems.length - 1
        ? 0
        : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0
        ? sliderItems.length - 1
        : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = sliderItems.map(item => {
    return (
      <CarouselItem
        onExiting={onExiting}
        onExited={onExited}
        key={item.src}>
        <img className="d-block w-100" src={item.src} alt={item.altText} />
        <CarouselCaption
          captionText={item.caption}
          captionHeader={item.caption}
        />
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}>
      <CarouselIndicators
        items={sliderItems}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>);
}
