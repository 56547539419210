import React, { useRef } from "react"
import Carousel from "../../Carousel";
import { BigSliderItems } from "../../../Code/Data";
import { SmallSliderItems } from "../../../Code/Data";
import styles from "./styles.module.scss"

export default function LandingPageCarousel() {
    const innerWidth = useRef(window.innerWidth);
    const showMinimized = innerWidth.current < 1080;
    return showMinimized
        ? <><Carousel sliderItems={SmallSliderItems} />
            <div className={styles.footer}>
                An official donation site of
                <br />
                the American Red Cross
            </div></>
        : <Carousel sliderItems={BigSliderItems} />
}