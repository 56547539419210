import React, { useState } from "react"
import { toast } from "react-toastify"
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
} from "react-share"
import { Button, RedBar } from "../../"
import { useRedemptionData } from "../../../Context/redemption"
import { SendDonorEmail } from "../../../Code/Data"

import ModalDonation from "../../Common/ModalDonation"
import styles from "./styles.module.scss"
import { Input } from "reactstrap"
import * as Confetti from "react-confetti"
import confettiImage from "../../../images/SuccessFireworks.png"

function SuccessRedemption({ charityName, isAddToDonation }) {
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [method, setMethod] = useState()
  const [infoVisible, setInfoVisible] = useState(!isAddToDonation)
  const [emailSent, setEmailSent] = useState(false)
  const { redemptionData } = useRedemptionData()
  const { Charities } = redemptionData
  const strCharities = Charities.map(({ CharityName }) => CharityName).join(
    ", ",
  )

  const onModalClose = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 7000,
    })
    setMethod()
  }
  const sendConfirmationEmail = async () => {

  }
  const sendDonorEmail = async () => {
    const { Succeeded, ErrorMessage } = await SendDonorEmail()
    setLoading(false)

    if (Succeeded) {
      setEmailSent(true)
      return
    }
    else {
      setError(ErrorMessage)
    }
  }

  return (
    <>
      {!isAddToDonation && <RedBar />}
      <Confetti
        colors={["red", "orange"]}
        numberOfPieces={200}
        recycle={false}
      />
      <div className={styles.container}>
        <div hidden={!infoVisible} className={styles.donateMethod}>
          <div className={styles.donationLabel}>
            How is my donation sent to the charities I choose?
          </div>
          <div className={styles.donationDescription}>
            Funds are allocated in a bulk check to the selected charities
            quarterly.
            <br />
            <br />
            Charities receive donations from us anonymously, in order to protect
            the privacy of both the donor and recipient. (unless you have opted
            in to share info) If your designation was to several charities, then
            the funds are divided equally.
            <br />
            <br />
            If you wish to apply the donation to a charity’s particular event or
            program; we suggest that you print a confirmation to verify your
            designation. It is up to each charity, as to their policy for
            ‘credits’ towards their programs.
          </div>
        </div>
        <div className={styles.content}>
          <img src={confettiImage} className={styles.confettiImage} />
          <div className={styles.title}> Success!</div>
          {isAddToDonation &&
            <>
              <span className={styles.description}>Your added donation has been processed successfully.</span>
              <br />
              <span className={styles.description}>Thank you for making a difference!</span>
            </>
          }

          {!isAddToDonation ? (
            charityName ? (
              <div className={styles.description}>
                Your gift amount will be distributed to
                <br />
                <span>{charityName}</span>
              </div>
            ) : (
              <div className={styles.description}>
                Your gift amount will be distributed &nbsp;
                {Charities.length === 1 ? 'to' : 'evenly between'}
                <br />
                <span>{strCharities}</span>
              </div>
            )
          ) : null}

          <div className={styles.lblMethod}>Would you like a receipt?</div>
          <div className={styles.methodWrapper}>
            <Button
              outline
              color={method === "donate" ? "yellow" : "red"}
              onClick={() => setMethod("donate")}>
              Donation Receipt
            </Button>
            <Button
              outline
              color={method === "tax" ? "yellow" : "red"}
              onClick={() => setMethod("tax")}>
              Tax Receipt
            </Button>
          </div>
          {!isAddToDonation &&
            <div>
              <div className={styles.spreadTheJoy}>
                <div className={styles.text}> Spread the joy, and share your donation!</div>
                <div className={styles.socialLinks}>
                  <EmailShareButton
                    url="https://www.charitygiftcertificates.org"
                    subject="I found this way of giving the most meaningful gift ever! Check it out."
                    body="Thank you for benefiting the charity of your choice through our service for charitable giving.
                    Funds are forwarded quarterly to the selected charities anonymously, to protect the privacy of users.
                    Allocations are sent to charities quarterly."
                    className={styles.btnShare}>
                    <i className="fa-solid fa-envelope"></i>
                  </EmailShareButton>
                  <FacebookShareButton
                    url="https://www.charitygiftcertificates.org"
                    quote="I found this way of giving the most meaningful gift ever! Check it out."
                    className={styles.btnShare}>
                    <i className="fa-brands fa-facebook"></i>
                  </FacebookShareButton>
                  <TwitterShareButton
                    url="https://www.charitygiftcertificates.org"
                    title="I found this way of giving the most meaningful gift ever! Check it out."
                    className={styles.btnShare}>
                    <i className="fa-brands fa-twitter"></i>
                  </TwitterShareButton>
                </div>
              </div>
              {!emailSent && !redemptionData.HasCharityId ? (
                <div className={styles.sendDonor}>
                  <div>
                    Send the donor an email notifying them which
                    {Charities.length > 1
                      ? " charities have been selected."
                      : " charity has been selected."}
                  </div>
                  <Input
                    className={styles.sendDonorCheckbox}
                    type="checkbox"
                    onClick={() => sendDonorEmail()}
                  /></div>
              ) : (emailSent &&
                <div className={styles.sentDonorEmail}>Email sent to donor.</div>
              )}
            </div>}
        </div>
        <ModalDonation type={method} onClose={onModalClose} />
      </div>
    </>
  )
}

export default SuccessRedemption
