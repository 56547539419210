import React, { useState } from "react"
import { Alert, UncontrolledTooltip } from "reactstrap"
import { useHistory } from "react-router-dom"
import { Button } from "../../.."
import { SetRedemptionPage } from "../../../../Code/Data"
import { useRedemptionData } from "../../../../Context/redemption"
import { useUserData } from "../../../../Context/user"
import { srcBgCharityPreselector } from "../../../../images"
import Modal from "../../../Shared/Modal"
import styles from "./styles.module.scss"

function Step3({ onContinue, onSuccess, setEditMode }) {
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const history = useHistory()
  const { loggedInUser } = useUserData()
  const [isModalOpen, setModalOpen] = useState(false)
  const { redemptionPageData } = useRedemptionData()
  const { UserId, HeaderImageURL, Caption, MainColor, AccentColor } =
    redemptionPageData
  const [hasChosenCharity, setChosenCharities] = useState(
    redemptionPageData?.Charities?.length
  )
  const isEditMode = JSON.parse(localStorage.getItem("isEditMode"))
  const buttonText =
    isEditMode && !hasChosenCharity ? "Save Changes" : "Continue"

  const handleSetEntireList = () => {
    if (!redemptionPageData.Charities.length) setChosenCharities(false)
    else {
      setModalOpen(true)
    }
  }

  const handleComplete = async () => {
    if (isEditMode) setEditMode(false)
    if (hasChosenCharity) {
      return onContinue()
    }

    const payload = {
      UserId,
      Charities: [],
      HeaderImageURL,
      Caption,
      MainColor,
      AccentColor,
    }
    setLoading(true)
    const { Succeeded, ErrorMessage } = await SetRedemptionPage(
      loggedInUser?.UserId,
      payload
    )
    setLoading(false)
    if (Succeeded) {
      history.push("/Account/CreateUserRedemptionPage?success=true")
      onSuccess()
    } else {
      setError(ErrorMessage)
    }
  }

  return (
    <div className={styles.container}>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        contentLabel="Action dialog">
        <p className="fs-4">
          Are you sure you want to display the entire list? Your charity
          selections will be lost.
        </p>
        <button
          className={styles.yesBtn}
          onClick={() => {
            setChosenCharities(false)
            setModalOpen(false)
          }}>
          Yes
        </button>
        <button className={styles.noBtn} onClick={() => setModalOpen(false)}>
          No
        </button>
      </Modal>
      <Alert color="danger" isOpen={!!error} toggle={() => setError()}>
        <div className={styles.errorWrapper}>{error}</div>
      </Alert>
      <img className={styles.imgTop} src={srcBgCharityPreselector} alt="" />
      <div className={styles.title}>
        How would you like charities displayed on your page?
      </div>
      <div className={styles.actions}>
        <Button
          className={styles.btnType}
          outline
          color={hasChosenCharity ? "yellow" : "red"}
          onClick={() => setChosenCharities(true)}>
          I'd like to pre-select charities
          <i id="btn-tooltip" className="fa fa-info-circle" />
          <UncontrolledTooltip placement="top" target="btn-tooltip">
            You can preselect preferred charities that will be highlighted on
            the charity redemption page for your gift recipients to redeem to
          </UncontrolledTooltip>
        </Button>
        <Button
          className={styles.btnType}
          outline
          color={hasChosenCharity ? "red" : "yellow"}
          onClick={handleSetEntireList}>
          Recipients can choose from entire list
        </Button>
      </div>
      <Button
        className={styles.btnContinue}
        loading={loading}
        onClick={handleComplete}>
        {buttonText}
      </Button>
    </div>
  )
}

export default Step3
