import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Switch, Route } from "react-router-dom"
import "../../css/scss/custom.scss"
import "../../css/theme/custom.css"
import "../../css/bootstrap-float-label.min.css"
import { registerAnalyticsPageView } from "../../Code/Utilities"
import UserRedemptionPage from "../../Components/UserRedemptionPage"
import { useUserData } from "../../Context/user"
import { useAppData } from "../../Context/appData"
import Main from "./Main"

let unregisterHistoryListener

export default function App() {
  const history = useHistory()
  const { loggedInUser } = useUserData()
  const { fullCharityList } = useAppData();
  useEffect(() => {

    const doStuff = async () => {
      const registerPageView = location => {
        const options = {
          page_title: document.title,
          page_location: window.location.href,
          page_path: location.pathname,
          user_id: (!!loggedInUser ? loggedInUser.UserId : undefined),
        }
        registerAnalyticsPageView(options)
        process.env.REACT_APP_DEV_BUILD === "true" &&
          console.log("Registered gtag page view: ", options)
      }
      if (!unregisterHistoryListener) {
        unregisterHistoryListener = history.listen(location => {
          registerPageView(location)
          process.env.REACT_APP_DEV_BUILD === "true" &&
            console.log("Registered History Listener")
        })
        //Register this first page...
        registerPageView(window.location)
      }

      console.log(`Charity list contains ${fullCharityList.length} charities`);

    }
    doStuff();
    
    return () => {
      unregisterHistoryListener()
      process.env.REACT_APP_DEV_BUILD === "true" &&
        console.log("Unregistered history listener.")
    }
  }, [])

  return (
    <Switch>
      {/*As these don't show a Header etc, they don't use Main*/}
      <Route path="/Redeem/Custom/:UserId?" component={UserRedemptionPage} />
      <Route path="/" component={Main} />
    </Switch>
  )
}
