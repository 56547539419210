import React from "react";
import { Container } from "reactstrap";
import "../../css/loadingSpinner.css";

export default function LoadingSpinner(props) {
  return (
    <Container className="loadingContainer">
      <h1 className="text-warn">{props.text}</h1>
      <div id="loader">
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        <div className="loading" />
      </div>
    </Container>
  );
}
