import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Container } from "reactstrap";
import { GetCharityInfo } from "../../Code/Data";
import MainPage from "../../Components/Shared/MainPage";
import PhysicalCardIcon from "../../images/physical-card-icon.svg"
import GiftCardCustomIcon from "./../../images/gift-card-custom.svg"
import DigitalCardIcon from "./../../images/digital-card-icon.svg"
import PrintCardIcon from "./../../images/print-card.svg"
import HonorCardIcon from "./../../images/honor-card-icon.svg"
import RedemptionCodesIcon from "./../../images/redemption-codes.svg"
import DonateIcon from "./../../images/donate.svg"
import DonateBalanceIcon from "./../../images/donate-balance.svg"
import styles from "./styles.module.scss"

export default function Support() {
  const { charityIdOrName } = useParams(),
    [charityInfo, setCharityInfo] = useState({
      CharityId: 0,
      CharityName: "Your Charity",
      CategoryName: "Other",
    });

  useEffect(() => {
    (async function f() {
      const result = await GetCharityInfo(charityIdOrName);
      if (result.CharityName) {
        setCharityInfo(result);
        document.title = `Support ${result.CharityName}`;
      }
    })();
  }, [charityIdOrName]);

  return (
    <MainPage
      title={`Support ${charityInfo.CharityName} and ${charityInfo.CategoryName} Causes`}
      description={
        <Container>
          <Row className="justify-content-md-center">
            {charityInfo.LogoURL && (
              <Col lg={3} className="text-center align-middle">
                <img
                  src={charityInfo.LogoURL}
                  style={{ maxWidth: "100%" }}
                  alt={charityInfo.CharityName}
                  className="inline"
                />
              </Col>
            )}
            <Col className="text-center md-auto">
              {charityInfo.ShortDescription && (
                <h5 className="smaller"
                  dangerouslySetInnerHTML={{
                    __html: charityInfo.ShortDescription,
                  }}
                />
              )}
            </Col>
          </Row>
        </Container>
      }>
      {charityInfo.CharityId > 0 && (
        <section>
          <Container>
            <Row className="section-title-row">
              <Col xs="12" className="text-center stepWay">
                <h2>{`Support ${charityInfo.CharityName}`}</h2>
              </Col>
            </Row>
            <Row className="justify-content-between">
              <SingleItem
                caption="Honor Cards"
                icon={HonorCardIcon}
                iconAlt="CharityChoice Honor Cards"
                text={`Make a donation to ${charityInfo.CharityName} "in honor of" your recipient.`}
                url={`/Purchase/HonorCards/?CharityId=${charityInfo.CharityId}`}
              />
              <SingleItem
                caption="Donate Directly"
                icon={DonateIcon}
                iconAlt="Donate to charity"
                text={`Use your credit card to donate through us to ${charityInfo.CharityName}`}
                url={`/Purchase/DirectToCharityDonation/?CHID=${charityInfo.CharityId}`}
              />
              <SingleItem
                caption="Donate Gift Card Balance"
                icon={DonateBalanceIcon}
                iconAlt="Donate Gift Card Balance"
                text={`Use your retail gift card balance to make a donation to ${charityInfo.CharityName}.`}
                url={`/Purchase/DonateYourCard/?CID${charityInfo.CharityId}`}
              />
            </Row>
          </Container>
        </section>
      )}
      <section>
        <Container>
          {charityInfo.CharityId > 0 && (
            <Row className="section-title-row">
              <Col xs="12" className="text-center stepWay">
                <h2>{`Support ${charityInfo.CategoryName} and other causes that resonate with you`}</h2>
              </Col>
            </Row>
          )}
          <Row className="justify-content-between">
            <SingleItem
              caption="Physical Cards"
              icon={PhysicalCardIcon}
              iconAlt="Buy the best charity gift cards for friends and clients. Holiday, Birthday, Seasons Greetings and Christmas Stocking Stuffers."
              text="Gift cards with one of our CharityChoice occasion designs; holiday, birthday, flower or charity ribbons."
              url="/Purchase/PhysicalCards"
              buttonText="Give Physical Cards"
            />
            <SingleItem
              caption="Digital Cards"
              icon={DigitalCardIcon}
              iconAlt="Send your best last-minute eGifts and stocking stuffers as eCards, text messages or post directly to friend's platform - Facebook, Twitter etc."
              text="Select a card to share by email, text, or social media. Last-minute gift idea; send now or later."
              url="/Purchase/DigitalCards"
              buttonText="Give Digital Cards"
            />
            <SingleItem
              caption="Print Your Own Cards"
              icon={PrintCardIcon}
              iconAlt="Print Your Own Cards. Donate in lieu of holiday gifts this year."
              text="Receive a high-quality PDF card to print at home."
              url="/Purchase/PrintCards"
              buttonText="Give Printable Cards"
            />
          </Row>
          <Row className="justify-content-between">
            <SingleItem
              caption="Custom Gift Cards"
              icon={GiftCardCustomIcon}
              iconAlt="Donate to purchase charity gift cards. Glossy Custom physical and Digital eGifts. Tax deductible, the best gifting idea."
              text={
                <>
                  Corporate and Events: Customize bulk physical cards
                  <br />
                  with your logo or your own, branded artwork.
                </>
              }
              url="/Purchase/PhysicalCards?type=3"
              buttonText="Give Custom Cards"
            />
            <SingleItem
              caption="Redemption Codes"
              icon={RedemptionCodesIcon}
              iconAlt="CSV file of redemption codes for clients. Redeem gift card for top, highly rated charities. Best nonprofits - local, national and International."
              text={
                <>
                  Buy unique codes to download and distribute
                  <br />
                  using your own materials via any method you wish.
                </>
              }
              url="/Purchase/RedemptionCodes"
              buttonText="Buy Redemption Codes"
            />
            <SingleItem
              caption="Honor Cards"
              icon={HonorCardIcon}
              iconAlt="CharityChoice Honor Cards. Give to charity as my Holiday gift."
              text='Make a donation to the charity of your choice "in honor of" your recipient.'
              url="/Purchase/HonorCards"
              buttonText="Give Honor Cards"
            />
          </Row>
          <Row className="justify-content-center">
            <SingleItem
              caption="Donate Directly"
              icon={DonateIcon}
              iconAlt="Donate to charity. Donate in lieu of Christmas gifts this year."
              text="eGifts that are giving back for friends and business."
              url="/Purchase/DirectToCharityDonation"
              buttonText="Donate Directly"
            />
            <SingleItem
              caption="Donate Gift Card Balance"
              icon={DonateBalanceIcon}
              iconAlt="Donate Gift Card Balance. Give to charity as my Christmas gift during COVID 19."
              text="Use your retail gift card balance to make a donation to a charity."
              url="/Purchase/DonateYourCard"
              buttonText="Donate Balance"
            />
          </Row>
        </Container>
      </section>
    </MainPage>
  );
}

function SingleItem({ caption, icon, iconAlt, text, url, onClick, buttonText }) {
  return (
    <Col xs="12" lg="4" className={styles.singleItem} key={caption}>
      <a href={url} onClick={onClick}>
        <div className={styles.description}>
          <img
            className={styles.icon}
            src={icon}
            alt={iconAlt}
          />
          <div className={styles.itemDetail}>
            <div className={styles.caption}>{caption}</div>
            <div className={styles.text}>{text}</div>
          </div>
        </div>
        {!!buttonText &&
          <div className={styles.link}>
            <a href={url} onClick={onClick}>{buttonText}</a>
          </div>
        }
      </a>
    </Col>
  )
}

