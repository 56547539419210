import React, { useState, useEffect } from "react"
import Slider from "react-slick"
import { GetHiddenCharities } from "../../../Code/Data";
import styles from "./styles.module.scss"

export default function CharitiesInCategorySlider({ categoryId, categoryName }) {
  const [charities, setCharities] = useState([]);

  useEffect(() => {
    const getCharities = async () => {
      const charityList = await GetHiddenCharities(categoryId);
      setCharities(charityList.map(c =>
        ({ logo: c.LogoURL, name: c.CharityName })));
    };
    getCharities();
  }, [categoryId]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>
          {categoryName} Charities
        </div>
        <Slider {...{
          className: styles.carouselContainer,
          variableWidth: true,
          dots: false,
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: true,
          speed: 3000,
          autoplaySpeed: 3000,
          cssEase: "linear"
        }}>
          {
            charities.map(({ logo, name }, index) => (
              <div key={index} className={styles.itemWrapper}>
                {!!logo
                  ? <img
                    className={styles.clientLogo}
                    src={logo}
                    alt={name}
                  />
                  : <h2 className={styles.charityName}>{name}</h2>}
              </div>
            ))
          }
        </Slider>
      </div>
    </div >
  )
}
