import React from "react"
import Slider from "react-slick"

import CBRELogo from "../../../images/cbre-logo.svg"
import CNNLogo from "../../../images/cnn-logo.svg"
import DellLogo from "../../../images/dell-logo.svg"
import JohnsonAndJohnsonLogo from "../../../images/johnsonandjohnson-logo.svg"
import MarriottLogo from "../../../images/marriott-logo.svg"
import MetLifeLogo from "../../../images/metlife-logo.svg"
import MicrosoftLogo from "../../../images/microsoft-logo.svg"
import NBCLogo from "../../../images/nbc-logo.svg"
import PepsiLogo from "../../../images/pepsi-logo.svg"
import UniversalLogo from "../../../images/universal-logo.svg"
import styles from "./styles.module.scss"

const Quotes = [{
  quote: (<>We used this as a customer gift, and it was extremely well received!<br />
    I love this website, we will definitely be using again!</>),
  quoter: <img src="/Images/BrookeRodruigez.png" alt="Our Southwest Airlines Business customer’s testimonial for how well received their charity Christmas gift idea was." />
}, {
  quote: '...they are a big hit and I am happy to offer this testimonial!',
  quoter: <img src="/Images/nasdaq-laura-hilgren.png" alt="Satisfied client testimonial from Nadaq, about how well received their charity gift idea for clients was." />
}, {
  quote: <>The CharityChoice program was an excellent way of giving back to those in need,<br />
    with a marketing program that was very well received by our clients around the world.<br />
    Enabling customers to choose their charity makes it personal, which results in high engagement.<br />
    It's also very easy to implement. I highly recommend CharityChoice.</>,
  quoter: <>Brad Mehl<br />Chief Marketing Officer<br /> <img src="/Images/coleman.png" alt="Coleman" /></>
}]

const Clients = [
  {
    logo: CBRELogo,
    name: "CBRE",
    alt: "CBRE buying our charity gift cards for gifting, showng their company values for social responsibility."
  },
  {
    logo: CNNLogo,
    name: "CNN",
    alt: "CNN buying our charity gift cards for christmas gifting, showng their company values for social responsibility."
  },
  {
    logo: DellLogo,
    name: "Dell",
    alt: "Dell buying our charity gift cards for holiday gifting, Christmas presents, showng their company values for social responsibility."
  },
  {
    logo: JohnsonAndJohnsonLogo,
    name: "Johnson And Johnson",
    alt: "Johnson And Johnson buying our charity gift cards for events gifting, showng their company values for social responsibility."
  },
  {
    logo: MarriottLogo,
    name: "Marriott",
    alt: "Marriott buying our charity gift cards for Seasons Greeting gifting, showng their company values for social responsibility."
  },
  {
    logo: MetLifeLogo,
    name: "MetLife",
    alt: "MetLife buying our charity gift cards for birthday gifting, showng their company values for social responsibility."
  },
  {
    logo: MicrosoftLogo,
    name: "Corporate Social Responsibility (CSR)",
    alt: "Corporate Social Responsibility (CSR) buying our charity gift cards for client gifting, showng their company values for social responsibility."
  },
  {
    logo: NBCLogo,
    name: "NBC",
    alt: "NBC buying our charity gift cards for employee gifting, showng their company values for social responsibility."
  },
  {
    logo: PepsiLogo,
    name: "PepsiCo",
    alt: "PepsiCo buying our charity gift cards for Christmas gifting, showng their company values for social responsibility."
  },
  {
    logo: UniversalLogo,
    name: "Universal",
    alt: "Universal buying our charity gift cards for Holiday gifting, showng their company values for social responsibility."
  },
]

export default function OurClients({ showMini }) {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {!showMini && <div className={styles.quoteSymbol}>“</div>}
        <div className={styles.title}>
          {showMini ? 'Our Corporate Clients' : 'What Our Corporate Clients Are Saying'}
        </div>
        {!showMini && <>
          <Slider {...{
            className: styles.quotesContainer,
            infinite: true,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            variableWidth: false
          }}>
            {Quotes.map(({ quote, quoter }, index) => (
              <div key={index} className={styles.quoteWrapper}>
                <div className={styles.quote}>
                  {quote}
                </div>
                <div className={styles.quoter}>
                  {quoter}
                </div>
              </div>
            ))}
          </Slider>
        </>}
        <Slider {...{
          className: styles.carouselContainer,
          infinite: true,
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          variableWidth: true
        }}>
          {
            Clients.map(({ logo, alt }, index) => (
              <div key={index} className={styles.itemWrapper}>
                <img
                  className={styles.clientLogo}
                  src={logo}
                  alt={alt}
                />
              </div>
            ))
          }
        </Slider>
      </div>
    </div >
  )
}
