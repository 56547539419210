import React, { useEffect, useState } from "react"
import { GetTitleAndMetaTags } from "../../Code/Data"
import { Helmet } from "react-helmet"

export default function TitleAndMetaTags({ pageName }) {
  const [title, setTitle] = useState("Charity|Choice Donation Gift Card: Virtual Events, Holiday Gifts, Birthday, Branded Business Gift Ideas,")
  const [keywords, setKeywords] = useState("")
  const [description, setDescription] = useState("The pioneer and leading Charity Gift, (also, an Official donation site of the Red Cross); offering 'giving back' for over 2000 charities. In lieu of, instead of standard gifts, as last-minute eGifts. Perfect for Virtual Events and keeping in touch with clients during COVID-19. Upload CSV file of recipients, robust corporate branding suite.")
  const [robots, setRobots] = useState("index, follow")

  useEffect(() => {
    async function fetchData() {
      const { PageTitle, Keywords, Description, Robots } = await GetTitleAndMetaTags(pageName)
      setTitle(PageTitle || title)
      setKeywords(Keywords || keywords)
      setDescription(Description || description)
      setRobots(Robots || robots)
    }

    if (pageName) {
      fetchData()
    }
  }, [])

  return (
    <Helmet>
      <meta name="keywords" content={keywords} />
      <meta name="description" content={description} />
      <meta name="robots" content={robots} />
      <title>{title}</title>
    </Helmet>
  )
}
